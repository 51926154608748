<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Change Schedule">
      <template #page-header>
        <h2 class="page-title">Change Schedule</h2>
        <r-menu-tab :level="3" />
      </template>
      <template #action-bar>
        <div class="columns">
          <div class="column is-9">
            <div class="level">
              <div class="level-left">
                <div
                  class="is-flex is-align-items-center mr-10px"
                  v-if="checkedRows.length > 0 && !isMassUpdating"
                >
                  <div
                    class="is-flex action-text"
                    @click="massUpdate('approved')"
                  >
                    <b-icon icon="check" type="is-success"></b-icon>
                    <p class="has-text-success">Approve</p>
                  </div>
                </div>
                <div
                  class="is-flex action-text is-align-items-center"
                  v-if="checkedRows.length > 0 && !isMassUpdating"
                >
                  <div class="is-flex" @click="massUpdate('declined')">
                    <b-icon icon="close" type="is-danger"></b-icon>
                    <p class="has-text-danger">Decline</p>
                  </div>
                </div>
              </div>
              <div class="level-right">
                <b-button
                  tag="button"
                  class="is-command visibility-hidden"
                  icon-left="plus"
                />
              </div>
            </div>
          </div>
          <div class="column is-3">
            <b-field class="search-container">
              <b-input
                expanded
                v-model="search"
                placeholder="Search"
                type="text"
                @keydown.native.enter="searchIconClick"
                icon-right="magnify"
                icon-right-clickable
                @icon-right-click="searchIconClick"
                :loading="isChangeScheduleListLoading"
              ></b-input>
            </b-field>
          </div>
        </div>
      </template>

      <template #page-content>
        <div id="table-request-employment" class="table-timeoff-type">
          <b-table
            :data="changeScheduleApproval"
            :per-page="perPage"
            :loading="isChangeScheduleListLoading"
            @page-change="onPageChange"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkable
            checkbox-position="left"
            :is-row-checkable="(row) => row.status === 'waiting'"
            :checked-rows.sync="checkedRows"
            class="employment-directory-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column label="No" v-slot="props" width="5%">
                <span class="is-capitalize">
                  {{ props.index + 1 }}
                </span>
              </b-table-column>

              <b-table-column
                field="requested_user.full_name"
                label="Full Name"
                v-slot="props"
                sortable
                width="23%"
              >
                <div class="columns employment-table-name">
                  <img
                    class="log-activity-profile-picture"
                    :src="
                      determineProfilePicture(
                        props.row.requestedUser.profilePictureUrl,
                        props.row.requestedUser.gender
                      )
                    "
                  />
                  <span class="is-capitalize">
                    {{ props.row.requestedUser.fullName }}
                  </span>
                </div>
              </b-table-column>

              <b-table-column
                field="date"
                label="Actual Date"
                v-slot="props"
                sortable
                width="13%"
              >
                <span class="is-capitalize">
                  {{ formatDate(props.row.date) }}
                </span>
              </b-table-column>

              <b-table-column
                field="change_date"
                label="Date Changed"
                v-slot="props"
                sortable
                width="13%"
              >
                <span class="is-capitalize">
                  {{ formatDate(props.row.changeDate) }}
                </span>
              </b-table-column>

              <b-table-column
                field="request_reason"
                label="Reason"
                v-slot="props"
                sortable
                width="27%"
              >
                <span>
                  {{ props.row.requestReason ? props.row.requestReason : '-' }}
                </span>
              </b-table-column>

              <b-table-column
                field="status"
                label="Status"
                v-slot="props"
                sortable
                width="10%"
              >
                <span
                  :class="`is-capitalize
                    ${determineFCByStatus(props.row.status)}`"
                >
                  {{ props.row.status }}
                </span>
              </b-table-column>

              <b-table-column label="Action" v-slot="props" width="5%">
                <b-icon
                  type="is-primary"
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="employment-edit-icon"
                  @click.native="openModalDetail(props.row)"
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>

        <r-page-modal
          :prompt-on-leave="false"
          prompt-message="Are You sure?"
          v-if="isModalOpen"
          @hide="closeModalEdit"
          :is-modal="true"
          class="modal-log-activity-management"
        >
          <template #page-content>
            <h3 class="modal-title has-text-weight-black">
              Change Date Request
            </h3>
            <div class="columns is-multiline has-text-color-label">
              <div class="column is-9 is-flex is-align-items-center">
                <img
                  class="selected-time-off-approval-profile-picture"
                  :src="
                    determineProfilePicture(
                      selectedChangeSchedule.requestedUser.profilePictureUrl
                    )
                  "
                />
                <div>
                  <span
                    class="time-off-approval-modal-content-full-name has-text-weight-semibold"
                  >
                    {{ selectedChangeSchedule.requestedUser.fullName }}
                  </span>
                  <br />
                  <span class="time-off-approval-modal-content-position-name">
                    {{ selectedChangeSchedule.requestedUser.position }}
                  </span>
                </div>
              </div>
              <div
                class="column is-3 is-flex is-align-items-center is-justify-content-flex-end"
              >
                <div
                  :class="`time-off-approval-modal-status-container has-text-white has-text-weight-semibold ${determineBGClassByStatus(
                    selectedChangeSchedule.status
                  )}`"
                >
                  {{ selectedChangeSchedule.status }}
                </div>
              </div>

              <div class="column is-5">
                <p class="has-text-weight-bold">
                  Actual Date
                  <br />
                  <span class="has-text-weight-normal">
                    {{ formatDate(selectedChangeSchedule.date) }}
                  </span>
                </p>
              </div>

              <div class="column is-7">
                <p class="has-text-weight-bold">
                  Date Changes To
                  <br />
                  <span class="has-text-weight-normal">
                    {{ formatDate(selectedChangeSchedule.changeDate) }}
                  </span>
                </p>
              </div>

              <div class="column is-12">
                <p class="has-text-weight-bold">
                  Request Reason
                  <br />
                  <span class="has-text-weight-normal">
                    {{
                      selectedChangeSchedule.requestReason
                        ? selectedChangeSchedule.requestReason
                        : '-'
                    }}
                  </span>
                </p>
              </div>
              <div class="column is-12">
                <p class="has-text-weight-bold">
                  Approved By
                  <br />
                  <TimeOffApprovalHistoryComponent
                    class="has-text-color-label"
                    :approval-history="selectedChangeSchedule.history"
                  />
                </p>
              </div>

              <div
                class="modal-approval-button-container"
                v-if="selectedChangeSchedule.status === 'waiting'"
              >
                <b-button
                  outlined
                  type="is-primary"
                  class="has-text-weight-bold has-text-black"
                  @click="
                    updateStatusApproval(selectedChangeSchedule.id, 'declined')
                  "
                  :disabled="disableDeclined || isUpdatingDeclined"
                >
                  {{ isUpdatingDeclined ? 'Declining...' : 'Decline' }}
                </b-button>
                <b-button
                  type="is-primary"
                  expanded
                  class="has-text-weight-bold"
                  @click="
                    updateStatusApproval(selectedChangeSchedule.id, 'approved')
                  "
                  :disabled="disableAccepted || isUpdatingAccepted"
                >
                  {{ isUpdatingAccepted ? 'Approving...' : 'Approve' }}
                </b-button>
              </div>
            </div>
          </template>
        </r-page-modal>
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment-timezone'
import {
  showToast,
  determineBackgroundColorByStatus,
  determineFontColorByStatus,
} from '@/services/util'
import TimeOffApprovalHistoryComponent from '../TimeOff/TimeOffApprovalHistoryComponent.vue'

export default {
  components: { TimeOffApprovalHistoryComponent },
  data() {
    return {
      stickyHeaders: true,
      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,
      checkedRows: [],

      defaultSortOrder: 'desc',
      sortField: 'request_attendance_adjustment_approvals.created_at',
      sortOrder: 'desc',
      search: '',

      isChangeScheduleListLoading: false,
      isLoadMore: false,
      isModalOpen: false,
      isEditing: false,

      selectedChangeSchedule: null,

      isUpdating: false,
      isUpdatingAccepted: false,
      isUpdatingDeclined: false,
      disableDeclined: false,
      disableAccepted: false,
      isMassUpdating: false,
    }
  },
  computed: {
    ...mapGetters({
      changeScheduleApproval: 'changeSchedule/getChangeScheduleApprovalData',
      currentUser: 'auth/currentUser',
    }),
  },
  methods: {
    ...mapActions({
      actionLoadChangeScheduleTeam:
        'changeSchedule/fetchChangeScheduleApprovalData',
      actionUpdateStatusApproval: 'changeSchedule/updateChangeScheduleApproval',
      actionMassUpdateStatusApproval:
        'changeSchedule/massUpdateChangeScheduleApproval',
    }),

    ...mapMutations({
      setChangeScheduleApprovalData:
        'changeSchedule/setChangeScheduleApprovalData',
    }),

    determineProfilePicture(picture, gender) {
      if (!picture) {
        if (gender === 'F') {
          // female
          return '/images/default-profile-picture-female.png'
        }
        // male
        return '/images/default-profile-picture-male.png'
      }

      return picture
    },

    determineBGClassByStatus(status) {
      return determineBackgroundColorByStatus(status)
    },

    determineFCByStatus(status) {
      return determineFontColorByStatus(status)
    },

    formatDate(str) {
      if (str) {
        return moment(str).format('DD MMMM YYYY')
      }
    },

    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadChangeScheduleTeamList()
        this.isLoadMore = false
      }
    },

    async searchIconClick() {
      await this.resetState()
    },

    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setChangeScheduleApprovalData([])
      await this.loadChangeScheduleTeamList()
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    onPageChange(page) {
      this.page = page
      this.loadChangeScheduleTeamList()
    },

    async loadChangeScheduleTeamList() {
      this.isChangeScheduleListLoading = true

      try {
        const response = await this.actionLoadChangeScheduleTeam({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: this.search,
        })

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isChangeScheduleListLoading = false
    },

    openModalDetail(row) {
      this.selectedChangeSchedule = row
      this.isModalOpen = true
    },

    closeModalEdit() {
      this.selectedChangeSchedule = null
      this.isModalOpen = false
    },

    async massUpdate(status) {
      let ids = []

      this.checkedRows.forEach((c) => ids.push(c.id))

      // trigger loading animation
      this.isMassUpdating = true

      try {
        await this.actionMassUpdateStatusApproval({ status, ids })
        showToast('Update Success', 'is-success', 'is-bottom')
      } catch (err) {
        if (err.response.data.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        }
        console.log(err)
      }
      this.isMassUpdating = false
      this.checkedRows = []
    },

    async updateStatusApproval(id, status) {
      const currentUserId = this.currentUser.id

      if (status === 'declined') {
        this.disableAccepted = true
        this.isUpdatingDeclined = true
      } else {
        this.disableDeclined = true
        this.isUpdatingAccepted = true
      }

      try {
        await this.actionUpdateStatusApproval({
          id,
          status,
          reportToId: currentUserId,
        })
        showToast('Update Success', 'is-success', 'is-bottom')
      } catch (err) {
        if (err.response.data.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        }
        console.log(err)
      }

      this.closeModalEdit()

      if (status === 'declined') {
        this.disableAccepted = false
        this.isUpdatingDeclined = false
      } else {
        this.disableDeclined = false
        this.isUpdatingAccepted = false
      }
    },
  },
  async mounted() {
    this.setChangeScheduleApprovalData([])
    await this.loadChangeScheduleTeamList()
  },
}
</script>
